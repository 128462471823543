<template>
  <div
    class="badge-item"
    :class="[
      { current: percent < 100 },
      { 'badge-item--large': large },
      badge.type.slug,
    ]"
  >
    <div
      v-if="badge.image && badge.image.url"
      class="badge-image"
    >
      <img
        :src="badge.image.url"
        :alt="badge.name"
        draggable="false"
        loading="lazy"
        width="350"
        height="165"
      />
    </div>
    <div class="content">
      <h5>{{ badge.name }}</h5>
      <p
        v-if="badge.description"
        class="description"
      >
        {{ badge.description }}
      </p>
      <div
        v-if="badge.points && !large"
        class="points"
      >
        {{ badge.points }} {{ pluralize('point', badge.points) }}
      </div>
      <p
        v-if="'unlock_percent' in badge && !large"
        class="compare"
      >
        Obtenu par {{ badge.unlock_percent }}% des joueurs
      </p>
    </div>
    <div
      v-if="total > 1 && !large"
      class="multiply"
    >
      <p>X {{ total }}</p>
    </div>
    <div
      v-if="percent < 100 && 'amount' in badge && badge.amount > 1 && !large"
      class="in-progress"
    >
      <ClientOnly>
        <ve-progress
          class="gauge"
          :progress="((amount || 0) / badge.amount) * 100"
          :size="90"
          line="square"
          :thickness="10"
          :empty-thickness="10"
          :color="gaugeColor"
          :empty-color="emptyGaugeColor"
          hide-legend
        />
      </ClientOnly>
      <p
        class="label"
        :style="{ color: gaugeColor }"
      >
        {{ amount || 0 }}/{{ badge.amount }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { BadgeReward, CustomBadge } from '~/types/badge'

const props = withDefaults(
  defineProps<{
    badge: CustomBadge | BadgeReward
    amount?: number
    percent?: number
    total?: number
    large?: boolean
    dragging?: boolean
  }>(),
  {
    amount: 0,
    percent: 100,
    total: 1,
    large: false,
    dragging: false,
  },
)

const gaugeColor = computed(() => {
  switch (props.badge.type.slug) {
    case 'gold':
      return '#FED02F'
    case 'silver':
      return '#AAAAAA'
    case 'bronze':
      return '#D5831F'
  }

  return ''
})

const emptyGaugeColor = computed(() => {
  switch (props.badge.type.slug) {
    case 'gold':
      return 'rgba(254  208  47 / 0.2)'
    case 'silver':
      return 'rgba(170  170  170 / 0.2)'
    case 'bronze':
      return 'rgb(213  131  31 / 0.2)'
  }

  return ''
})
</script>

<style lang="scss" scoped>
.badge-item {
  $root: &;

  position: relative;
  width: 35rem;
  height: 16.5rem;
  overflow: hidden;
  border-bottom-left-radius: 2rem;

  &--large#{$root} {
    display: flex;
    align-items: center;
    width: 100%;
    height: 13.5rem;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      padding: 3rem;
      text-align: left !important;

      h5 {
        font-size: 2.2rem;
      }

      .description {
        height: auto;
        margin-top: 1.2rem;
        padding-right: 0;
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: $white;
    border: 0.2rem solid #e5e5e5;
    border-bottom-left-radius: 2rem;
  }

  .badge-image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: right;
      opacity: 0.1;
    }
  }

  &:not(.current) {
    &.gold,
    &.silver,
    &.bronze {
      .badge-image img {
        opacity: 1;
      }

      .content {
        h5 {
          color: $white;
        }

        .description {
          color: $white;
        }
      }
    }

    &.gold {
      &::before {
        background-color: #ffcb17;
        background-image: radial-gradient(circle at 75%, #ffdd67, #ffcb17);
        border-color: #fcc402;
      }

      .content {
        .compare {
          color: #bfa23a;
        }
      }
    }

    &.silver {
      &::before {
        background-color: #a3a3a3;
        background-image: radial-gradient(circle at 75%, #cecece, #a3a3a3);
        border-color: #a5a5a5;
      }

      .content {
        .compare {
          color: #5d5d5d;
        }
      }
    }

    &.bronze {
      &::before {
        background-color: #d88520;
        background-image: radial-gradient(circle at 75%, #f0aa53, #d88520);
        border-color: #d38220;
      }

      .content {
        .compare {
          color: #885211;
        }
      }
    }
  }

  .content {
    position: relative;
    z-index: 1;
    padding: 2rem;
    color: $white;
    text-align: left !important;

    h5 {
      color: $blue;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.1rem;
    }

    .description {
      margin-top: 0.5rem;
      padding-right: 10rem;
      color: rgba(#000, 0.4);
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.25;
    }

    .points {
      display: inline-block;
      margin-top: 1rem;
      margin-bottom: 1.2rem;
      padding: 0 1.4rem;
      color: $white;
      font-family: 'SST Condensed';
      font-size: 1rem;
      font-weight: bold;
      line-height: 3rem;
      text-transform: uppercase;
      background: $blue;
    }

    .compare {
      color: rgba(#000, 0.3);
      font-size: 1rem;
      line-height: 1.3rem;
    }
  }

  .multiply {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    width: 10rem;
    height: 6.8rem;
    background-image: url('~/assets/img/badge-corner.png');
    background-size: cover;

    p {
      position: absolute;
      top: 1.2rem;
      right: -2.8rem;
      z-index: 1;
      width: 100%;
      color: $white;
      font-size: 1.1rem;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      transform: rotate(41deg);
    }
  }

  .in-progress {
    position: absolute;
    top: 50%;
    right: 2rem;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 9rem;
    height: 9rem;
    transform: translateY(-50%);

    .gauge {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .label {
      font-family: 'SST Condensed';
      font-size: 1.6rem;
      font-weight: bold;
    }
  }
}
</style>
